<template>

    <div class="row mb-2 align-items-end">

        <div v-if="ShowTodayButton" :class="{'col-6 col-md': true, 'col-12' : inModal}">
            <div>
                <label>{{ startLabel }}</label>
            </div>
            <div
                class="input-group daterange"
            >
                <div class="input-group-prepend">
                    <b-dropdown v-b-tooltip.hover="$t('table.general.reset_to_today')" class="dropdown_date_range">
                        <template v-slot:button-content >
                            <i><font-awesome-icon :icon="['fal', 'calendar']" /></i>
                        </template>
                        <b-dropdown-item class="w-100" style="min-width: none;" @click="ChangeDate(0)">{{ $t('global.J') }}</b-dropdown-item>
                        <b-dropdown-item class="w-100"  @click="ChangeDate(1)">{{ $t('global.J+1') }}</b-dropdown-item>
                        <b-dropdown-item class="w-100"  @click="ChangeDate(2)">{{ $t('global.J+2') }}</b-dropdown-item>
                        <b-dropdown-item class="w-100"  @click="ChangeDate(3)">{{ $t('global.J+3') }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                
                <e-datepicker v-model="internalStart"></e-datepicker>
                
            </div>
        </div>

        
        <div v-else :class="{'col-6 col-md': true, 'col-12' : inModal}">
            <label>{{ startLabel }}</label>
            <e-datepicker v-model="internalStart"></e-datepicker>
        </div>
        
        <div :class="{'col-6 col-md': true, 'col-12' : inModal}">
            <label>{{ endLabel }}</label>
            <e-datepicker v-model="internalEnd"></e-datepicker>
        </div>
        <div :class="{'col-12 col-md mt-2 mt-md-0': true, 'col-12' : inModal}">
            <button @click.prevent="onSubmit" class="btn btn-block btn-primary">{{ $t('global.rechercher') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DateRange',
    props: {
        start: {
            type: Date,
            default: () => (null)
        },
        startDefault: {
            type: Date,
            default: () => (null)
        },
        startLabel: {
            type: String,
            default: function() { return this.$t('global.date_debut') }
        },
        end: {
            type: Date,
            default: () => (null)
        },
        endDefault: {
            type: Date,
            default: () => (null)
        },
        endLabel: {
            type: String,
            default: function() { return this.$t('global.date_fin') }
        },
        inModal: {
            type: Boolean,
            default: function() { return false }
		},
		persistId: {
			type: String,
			default: ''
		},
        persistDuration: { // en jours
            type: Number,
            default: 0 // Si 0, la persistDuration n'est pas prise en compte
        },
        ShowTodayButton: {
            type: Boolean,
            default: function() { return false }
		},
    },
    computed: {
		persistParams() {
			return this.persistId != ''
		},
        internalStart: {
            get() {
                return this.start
            },
            set(val) {
				if(val) {
					val.setHours(0)
					val.setMinutes(0)
					val.setSeconds(0)
				}
				this.$emit('update:start', val)
            }
        },
        internalEnd: {
            get() {
                return this.end
            },
            set(val) {
				if(val) {
                    val.setHours(23)
					val.setMinutes(59)
					val.setSeconds(59)
				}
				this.$emit('update:end', val)
            }
        }
	},
	created() {
		if(this.persistParams) {
			const start = this.getDateFilters('begin')
			if(start) {
				this.$emit('update:start', start)
			}
			else if(this.startDefault) {
				this.$emit('update:start', this.startDefault)
			}

			const end = this.getDateFilters('end')
			if(end) {
				this.$emit('update:end', end)
			}
			else if(this.endDefault) {
				this.$emit('update:end', this.endDefault)
			}

			this.$emit('submit')
		}
	},
    methods: {
        onSubmit() {
			if(this.persistParams) {
				this.saveDateFilters('start', this.internalStart)
				this.saveDateFilters('end', this.internalEnd)
			}
            this.$emit('submit')
		},
		getDateFilters(type='begin') {
			const filters = this.$store.getters["table/getTableDate"](this.persistId)
        
			if(!filters)  {
				if(type == 'begin') return this.start
				return this.end
			}

			if(type == 'begin') return filters.begin
			return filters.end
		},
		saveDateFilters(type, val) {
            const now = new Date()
			this.$store.commit('table/setTableDate', {
				tableId: this.persistId,
				beginDate: type == 'start' ? val : this.internalStart,
				endDate: type == 'end' ? val : this.internalEnd,
			})
		},
		ChangeDate(day) {
            let today = new Date()
            today.setDate(today.getDate() + day);
            this.internalStart = today
            this.internalEnd = today
		},
    }
}
</script>
